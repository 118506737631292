import React from 'react';
import './App.css';
import Iframe from './Iframe';

function App() {
  return (
    <div>
      <Iframe />
    </div>
  );
}

export default App;
