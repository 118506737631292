import React from 'react';

function Iframe() {
  return (
    <div>
      <iframe
        title="Web View"
        src="https://autopecas3irmaos.com.br/"
        style={{ width: '100%', height: '100vh' }}
      />
    </div>
  );
}

export default Iframe;